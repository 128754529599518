import { bind } from 'hyperhtml';

import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { isMobileSafari } from 'Shared/Helper/Browser/Browser';
import { findOne, findAll } from 'Shared/Helper/Dom/Dom';
import { getLocale } from 'Shared/Helper/Env/Locale';
import { throwError } from 'Shared/Helper/Error/Error';
import { formDataFromObject } from 'Shared/Helper/FormData/CreateFromObject';
import { pruneFormData } from 'Shared/Helper/FormData/Prune';
import { queryStringToObject } from 'Shared/Helper/QueryString/CreateToObject';
import { sanitizeQueryString } from 'Shared/Helper/QueryString/Sanitize';
import { LOCATION_TYPES } from 'Shared/Helper/SearchFilters/LocationTypes';
import { SearchQuery } from 'Shared/Helper/SearchQuery/SearchQuery';
import { sleep } from 'Shared/Helper/Sleep/Sleep';
import { isMobile } from 'Shared/Helper/ViewPort/ViewPort';

const PARENT_NAME = 'page';
const NAME = 'search-page';

const CLASSES = {
    'filterHighlightRow': renderClasses(PARENT_NAME, 'row', ['filter-highlight']),
    'results': renderClasses(PARENT_NAME, 'results'),
    'searchFiltersModal': renderClasses('modal', null, ['search-filters']),
    'modalBody': renderClasses('modal', 'body'),
    'masthead': renderClasses(PARENT_NAME, 'row', ['masthead']),
    'searchControls': renderClasses(PARENT_NAME, 'wrapper', ['search-controls']),
    'searchFilters': renderClasses('search-filters', 'filters'),
    'searchFilterCollapsed': renderClasses('search-filter', null, ['collapsed']),
    'searchFilter': renderClasses('search-filters', 'filter'),
};

const SELECTORS = {
    'filterHighlightRow': `.${CLASSES.filterHighlightRow}`,
    'results': `.${CLASSES.results}`,
    'searchResulsModalBody': `.${CLASSES.searchFiltersModal} .${CLASSES.modalBody}`,
    'masthead': `.${CLASSES.masthead}`,
    'searchControls': `.${CLASSES.searchControls}`,
    'searchFilters': `.${CLASSES.searchFilters}`,
    'searchFilterCollapsed': `.${CLASSES.searchFilterCollapsed}`,
    'searchFilter': `.${CLASSES.searchFilter}`,
    'metaDescription': '[name="description"]',
    'ogDescription': '[property="og:description"]',
    'metaCanonical': '[rel="canonical"]',
    'ogUrl': '[property="og:url"]',
    'metaRobots': '[name="robots"]',
};

/*
    TODO: rename messages (and name) to refelect class name change.
*/
const MESSAGES = {
    'ready': 'searchPageSearchQueryReady',
    'stateChanged': 'searchPageSearchQueryStateChanged',
    'changed': 'searchPageSearchQueryChanged',
    'changing': 'searchPageSearchQueryChanging',
    'urlChanged': 'searchPageUrlChanged',
};

const MESSAGE_HANDLERS = [
    'checkboxControlCleared.all',
    'radioControlCleared.all',
    'filterHighlightRemoved.all',
    'searchFiltersGroupActive.all',
    'searchFiltersGroupInactive.all',
    'searchFiltersGroupClearClicked.all',
    'searchFiltersLocationChanged.all',
    'searchFiltersSubmitted.all',
    'searchFiltersSummaryFilterRemoved.all',
    'searchFiltersSummaryAllFiltersRemoved.all',
    'searchListSortingChanged.all',
    'searchMapReady.all',
    'searchMapZoomCenterChanged.all',
    'searchPageUrlChanged.all',
    'paginationClicked.all',
];

function searchPageControllerFactory (dependencies) {
    return class ListingSearchPage extends dependencies.pageController {
        initialize () {
            this.searchQuery = null;
            this.searchMapReady = false;

            this.bindMethodsToSelf([
                'onPopState',
                'onSearchFiltersSubmitted',
            ]);

            this.locale = getLocale(dependencies.supportedLocales);

            // Safari is the only browser that fires the popstate event on page load ... because of course.
            // Having popstate fired on page load messes with the little thing we have going on here so in Safari
            // we wait until the first popstate is fired to register our own popstate handler
            if ('safari' in window) {
                window.addEventListener('popstate', () => {
                    window.addEventListener('popstate', this.onPopState);
                }, {
                    'once': true,
                });
            } else {
                window.addEventListener('popstate', this.onPopState);
            }

            super.initialize();
        }

        async connect () {
            super.connect();

            const viewOptions = this.getJSONData('view-options');
            const sortingOptions = this.getJSONData('sorting-options');
            const filters = this.getJSONData('filters');

            this.searchQuery = new SearchQuery();

            this.resetSearchQuery(filters, viewOptions, sortingOptions);

            if (this.isMapView) {
                // TODO: Can be defined on load as data.
                const mapOptions = queryStringToObject(sanitizeQueryString(document.location.search));

                // Set map options with zoom center from querystring.
                await this.searchQuery.setMapOptions(mapOptions);

                if (isMobileSafari()) {
                    window.addEventListener('orientationchange', () => {
                        window.requestAnimationFrame(() => {
                            document.documentElement.style.height = '100vh';

                            window.setTimeout(() => {
                                document.documentElement.style.height = '';
                            }, 400);
                        });
                    });
                }
            }

            history.replaceState(this.searchQuery.getState(), null, document.location.href);

            this.postReady();
        }

        async onPopState (evt) {
            if (evt.state === null) {
                return;
            }

            this.searchQuery.resetState(evt.state);

            await this.postStateChanged();
        }

        async onSearchFiltersSubmitted (evt) {
            this.updateFilters(evt.data.data.filters);

            if (this.isMapView) {
                this.searchQuery.setMapOptions();
            }

            await this.postChanged();
        }

        async onSearchListSortingChanged (evt) {
            const { sortingOptions } = evt.data.data;

            this.searchQuery.setSortingOptions(sortingOptions);
            this.searchQuery.resetPage();

            await this.postChanged();
        }

        async onPaginationClicked (evt) {
            const { page } = evt.data.data;

            this.searchQuery.setPage(page);

            await this.postChanged();
        }

        async onSearchFiltersLocationChanged (evt) {
            const filters = evt.data.data.filters;

            this.updateFilters(filters);
            this.searchQuery.resetSorting();

            if (this.isMapView) {
                // Reset map options (zoom, center, geojson).
                await this.searchQuery.setMapOptions();
            }

            await this.postChanged();
        }

        async onSearchFiltersGroupClearClicked (evt) {
            const removedName = evt.data.data.searchFiltersGroupName.replace('-', '_');

            if (this.searchQuery.filters.has(`filters[${removedName}][min]`)) {
                await this.removeSearchFilter(`filters[${removedName}][min]`, null);

                return;
            }

            await this.removeSearchFilter(`filters[${removedName}]`, null);
        }

        updateFilters (filters) {
            const isLocationChanged = this.isLocationChanged(filters);

            this.searchQuery.setFilters(filters);
            this.searchQuery.resetPage();

            const newFilters = new Map(filters);

            if (isLocationChanged) {
                LOCATION_TYPES.forEach(locationType => {
                    const key = `filters[${locationType}]`;

                    if (!newFilters.has(key)) {
                        this.searchQuery.filters.delete(key);
                    }
                });
            }
        }

        async onSearchFiltersSummaryAllFiltersRemoved () {
            const currentFilters = this.searchQuery.filters;
            const newFilters = new FormData();
            const keepFilters = ['type', 'country'];

            keepFilters.forEach(filterName => {
                const key = `filters[${filterName}]`;

                newFilters.append(key, currentFilters.get(key));
            });

            this.searchQuery.filters = newFilters;

            await this.postChanged();
        }

        async onSearchFiltersSummaryFilterRemoved (evt) {
            const removedName = evt.data.data.name;
            const removedValue = evt.data.data.value;

            await this.removeSearchFilter(removedName, removedValue);
        }

        async onRadioControlCleared (evt) {
            const removedName = evt.data.data.name;
            const removedValue = evt.data.data.value;

            await this.removeSearchFilter(removedName, removedValue);
        }

        async onCheckboxControlCleared (evt) {
            const removedName = evt.data.data.name;

            // Don't pass the value so the check in `removeSearchFilter` passes and `removeFilter` becomes true
            // (somehow the values of boolean filters in the state object have a value of integer 1 while the form fields have a value of string "true") so they wouldn't match
            await this.removeSearchFilter(removedName);
        }

        async removeSearchFilter (removedName, removedValue) {
            const currentFilters = this.searchQuery.filters;
            const newFilters = new FormData();

            for (const [key, value] of currentFilters) {
                let removeFilter = false;

                if (removedName == key) {
                    if (removedValue == value || removedValue == null) {
                        removeFilter = true;
                    }
                }

                if (!removeFilter) {
                    newFilters.append(key, value);
                } else if (key == 'filters[price][min]' || key == 'filters[contract_duration][min]') { // special case: removing minimum value actually means setting it to zero
                    newFilters.append(key, '0');
                } else if (key === 'filters[price][max]' || key === 'filters[contract_duration][max]') { // special case: removing upper value actually means setting it to max
                    const optionElements = findAll(`[name="${key}"] option`);

                    const lastMaxValue = optionElements[optionElements.length - 1]; // assume the last value is the highest
                    const fakeMaxValue = Number(lastMaxValue.value) * 10;
                    newFilters.append(key, fakeMaxValue);
                }
            }

            this.searchQuery.filters = newFilters;

            await this.postChanged();
        }

        onSearchFiltersGroupActive () {
            // prevent the page from scrolling when the 'modal' for a search filter is open, so the search filter 'modal' is the only thing that can scroll
            if (isMobile()) {
                document.body.style.overflow = 'hidden';
            }
        }

        onSearchFiltersGroupInactive () {
            if (isMobile()) {
                document.body.style.overflow = '';
            }
        }

        onSearchPageUrlChanged () {
            const city = this.searchQuery.filters.get('filters[city]');

            if (('googletag' in window) && googletag.cmd) {
                googletag.cmd.push(() => {
                    googletag.pubads()
                        .setTargeting('city', city)
                        .updateCorrelator()
                        .refresh();
                });
            }
        }

        onSearchMapZoomCenterChanged (evt) {
            const { zoom, center } = evt.data.data;
            this.searchQuery.setZoomCenter(zoom, center);

            // Just update the zoom/center querystring in the url and replace the current history state.
            const url = `${document.location.pathname}?zoom=${this.searchQuery.mapOptions.zoom}&center=${this.searchQuery.mapOptions.center}`;
            history.replaceState(this.searchQuery.getState(), null, url);
        }

        onSearchMapReady () {
            this.searchMapReady = true;
        }

        onFilterHighlightRemoved () {
            const pageRow = findOne(SELECTORS.filterHighlightRow, this.element);

            pageRow.parentNode.removeChild(pageRow);
        }

        postReady () {
            const payload = this.searchQuery.getState();

            const callback = () => {
                this.messageBus.postMessage({
                    'message': MESSAGES.ready,
                    'data': payload,
                });
            };

            // When on map we need to wait for the map to be ready.
            if (this.isMapView) {
                this.waitForSearchMapReady(callback);
            } else {
                callback();
            }
        }

        async postChanged () {
            const start = Date.now();

            this.messageBus.postMessage({
                'message': MESSAGES.changing,
            });

            const response = await this.fetchContent();

            if (!response) {
                return;
            }

            const now = Date.now();

            // make sure the response always takes at least 750ms so the user can see stuff changing.
            if (now - start < 750) {
                await sleep(now - start);
            }

            this.updateUI(response.content.components);
            this.resetSearchQuery(
                response.content.search_query.filters,
                response.content.search_query.view_options,
                response.content.search_query.sorting_options,
            );

            if ('_meta' in response.content) {
                this.updateMeta(response.content._meta);
            }

            this.messageBus.postMessage({
                'message': MESSAGES.changed,
                'data': this.searchQuery.getState(),
            });

            const urls = response.urls;

            this.updateUrl(urls);
        }

        async postStateChanged () {
            const response = await this.fetchContent();

            if (!response) {
                return;
            }

            this.updateUI(response.content.components);
            this.resetSearchQuery(
                response.content.search_query.filters,
                response.content.search_query.view_options,
                response.content.search_query.sorting_options,
            );

            if ('_meta' in response.content) {
                this.updateMeta(response.content._meta);
            }

            this.messageBus.postMessage({
                'message': MESSAGES.stateChanged,
                'data': this.searchQuery.getState(),
            });

            this.updateUrl(response.urls, true);
        }

        async updateUI (content) {
            if (this.isListView) {
                const results = await this.moveAssetsToHead(content.results);
                this.updateResultsComponent(results);
            }

            const searchFilters = await this.moveAssetsToHead(content.search_filters);
            this.updateFiltersComponent(searchFilters);

            const masthead = await this.moveAssetsToHead(content.masthead);
            this.updateMastheadComponent(masthead);

            const searchControls = await this.moveAssetsToHead(content.search_controls);
            this.updateSearchControlsComponent(searchControls);
        }

        async moveAssetsToHead (content) {
            const dummy = document.createElement('div');
            dummy.innerHTML = content;

            const links = findAll('link', dummy);
            links.forEach(link => {
                const href = (new URL(link.href)).pathname;
                const existing = findOne(`link[href="${href}"]`);
                if (existing) {
                    return;
                }

                const newLink = document.createElement('link');
                for (const attr of link.attributes) {
                    newLink.setAttribute(attr.name, attr.value);
                }

                document.head.appendChild(newLink);
                link.parentNode.removeChild(link);
            });

            const scripts = findAll('script', dummy);
            const importPromises = [];
            scripts.forEach(script => {
                const parent = script.parentNode;

                if (script.src) {
                    const src = (new URL(script.src)).pathname;
                    const existing = findOne(`script[src="${src}"]`);
                    if (existing) {
                        return;
                    }

                    importPromises.push(import(script.src));
                } else {
                    const scriptElement = document.createElement('script');
                    scriptElement.innerHTML = script.innerHTML;
                    parent.appendChild(scriptElement);
                }

                parent.removeChild(script);
            });

            await Promise.all(importPromises);

            return dummy.innerHTML;
        }

        async updateMeta (content) {
            const metaDescription = findOne(SELECTORS.metaDescription);
            const metaCanonical = findOne(SELECTORS.metaCanonical);
            const metaRobots = findOne(SELECTORS.metaRobots);
            const ogDescription = findOne(SELECTORS.ogDescription);
            const ogUrl = findOne(SELECTORS.ogUrl);

            metaDescription.setAttribute('content', content.meta_description);
            metaCanonical.setAttribute('href', content.canonical_url);
            metaRobots.setAttribute('content', content.robots);

            ogDescription.setAttribute('content', content.meta_description);
            ogUrl.setAttribute('content', content.canonical_url);

            document.title = content.meta_title;

            if ('assets' in content) {
                await this.moveAssetsToHead(content.assets);
            }
        }

        updateResultsComponent (results) {
            const searchResults = findOne(SELECTORS.results);

            searchResults.outerHTML = results;

            window.scrollTo(0, 0);
        }

        updateFiltersComponent (searchFilters) {
            const searchFiltersParent = findOne(SELECTORS.searchResulsModalBody);
            const searchFiltersNode = findOne(SELECTORS.searchFilters, searchFiltersParent);

            const collapsedFilters = findAll(SELECTORS.searchFilterCollapsed, searchFiltersNode);
            const collapsedFiltersClasses = collapsedFilters.map(node => {
                return node.className
                    .replace(CLASSES.searchFilterCollapsed, '')
                    .trim();
            });

            searchFilters = searchFilters.replaceAll(CLASSES.searchFilterCollapsed, '');

            collapsedFiltersClasses.forEach(className => {
                searchFilters = searchFilters
                    .replace(`class="${className}`, `class="${className} ${CLASSES.searchFilterCollapsed}`); //the missing closing quote is intentional
            });

            const scrollPosition = searchFiltersNode.scrollTop;

            const allSearchFilters = findAll(SELECTORS.searchFilter, searchFiltersParent);
            let firstFullyVisibleFilter = null;

            for (const filter of allSearchFilters) {
                if (filter.offsetTop >= scrollPosition) {
                    firstFullyVisibleFilter = {
                        className: filter.className,
                        offset: filter.offsetTop - scrollPosition,
                    };

                    break;
                }
            }

            bind(searchFiltersParent)`${[searchFilters]}`;

            const firstFullyVisibleFilterElement = findOne(`[class="${firstFullyVisibleFilter.className}"]`, searchFiltersParent);

            const newSearchFiltersNode = findOne(SELECTORS.searchFilters, searchFiltersParent);
            newSearchFiltersNode.scrollTo(0, firstFullyVisibleFilterElement.offsetTop - firstFullyVisibleFilter.offset);
        }

        updateMastheadComponent (masthead) {
            const mastheadParent = findOne(SELECTORS.masthead);

            bind(mastheadParent)`${[masthead]}`;
        }

        updateSearchControlsComponent (searchControls) {
            const searchControlsParent = findOne(SELECTORS.searchControls);

            bind(searchControlsParent)`${[searchControls]}`;
        }

        async fetchContent () {
            if (!this.searchQuery) {
                throwError('SearchQuery is undefined');

                return false;
            }

            let body = new FormData();

            for (const [key, value] of this.searchQuery.filters.entries()) {
                body.append(key, value);
            }

            for (const [key, value] of this.searchQuery.viewOptions.entries()) {
                body.append(key, value);
            }

            for (const [key, value] of this.searchQuery.sortingOptions.entries()) {
                body.append(key, value);
            }

            const { center, zoom } = this.searchQuery.mapOptions;

            if (zoom && center) {
                body.append('zoom', zoom);
                body.append('center', center.toString());
            }

            body = pruneFormData(body);

            // Fetch the URLs (to update list/map toggle, language switcher, etc) representing the current set of active filters
            const response = await fetch(Platform.uris.get_search_urls, {
                'headers': {
                    'X-Requested-With': 'XMLHttpRequest',
                },
                'body': body,
                'method': 'POST',
            })
                .then(response => response.json())
                .catch(() => null);

            if (!response) {
                return null;
            }

            // Fetch partials of updated page elements (search filters, masthead, result list)
            const contentResponse = await fetch(response.urls[this.view][this.locale], {
                'headers': {
                    'X-Requested-With': 'XMLHttpRequest',
                },
                'method': 'GET',
            })
                .then(response => response.json())
                .catch(() => null);

            if (!response) {
                return null;
            }

            response.content = contentResponse;

            return response;
        }

        isLocationChanged (filters) {
            const newFilters = new Map(filters);
            const currentFilters = this.searchQuery.filters;

            return LOCATION_TYPES.some(locationType => {
                const key = `filters[${locationType}]`;

                return newFilters.get(key) && newFilters.get(key) !== currentFilters.get(key);
            });
        }

        updateUrl (urls, silent = false) {
            this.messageBus.postMessage({
                'message': MESSAGES.urlChanged,
                'data': {
                    'urls': urls,
                    'view': this.view,
                },
            });

            const url = `${urls[this.view][this.locale]}`;

            if (!silent) {
                history.pushState(this.searchQuery.getState(), null, url);
            }
        }

        waitForSearchMapReady (cb) {
            window.setTimeout(() => {
                if (this.searchMapReady) {
                    cb();
                } else {
                    this.waitForSearchMapReady(cb);
                }
            }, 10);
        }

        resetSearchQuery (filters, viewOptions, sortingOptions) {
            this.searchQuery.resetState({
                'filters': formDataFromObject(filters),
                'viewOptions': formDataFromObject(viewOptions),
                'sortingOptions': formDataFromObject(sortingOptions),
                'mapOptions': this.searchQuery.mapOptions,
            });
        }

        get view () {
            return this.searchQuery.viewOptions.get('view_options[view]');
        }

        get isMapView () {
            return this.view == 'map';
        }

        get isListView () {
            return this.view == 'list';
        }

        get componentName () {
            return NAME;
        }

        get messageHandlers () {
            return MESSAGE_HANDLERS.concat(super.messageHandlers);
        }
    };
}

export { searchPageControllerFactory };
